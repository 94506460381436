import { graphql, PageProps } from 'gatsby';

import { StandardLayout } from '~/components/layout';
import { RichText } from '~/components/rich-text';
import { Section } from '~/components/section';
import { isRichText } from '~/utilities/rich-text.utility';

const VisionPage = ({ data }: PageProps<Queries.VisionPageQuery>) => {
  const person = data.contentfulPerson;

  return (
    <StandardLayout>
      <Section title="Vision">
        {isRichText(person?.vision) && (
          <RichText content={person?.vision} variant="two-column" />
        )}
      </Section>
    </StandardLayout>
  );
};

export default VisionPage;

export const pageQuery = graphql`
  query VisionPage {
    contentfulPerson(isPortfolioOwner: { eq: true }) {
      vision {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            title
            description
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
